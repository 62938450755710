import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {isPlatformBrowser, isPlatformServer} from "@angular/common";

@Component({
  selector: 'app-title-display',
  templateUrl: './title-display.component.html',
  styleUrl: './title-display.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleDisplayComponent implements  OnChanges, OnInit{
  @Input({transform: (value: string) => value.trim() }) title: string = '';
  @Input() productCount: number = 0;
  displayTitle: string = '';
  @ViewChild('titleElement') titleElement : ElementRef | undefined;

  constructor(
    public changeDetectionRef: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['title']) {
      if(changes['title'].currentValue !== changes['title'].previousValue) {
        this.displayTitle = '';
        this.setSpacesTitle();
      }
    }
  }

  setSpacesTitle() {
    if(isPlatformServer(this.platformId)) {
      return;
    }
    setTimeout(() => {
      if(isPlatformBrowser(this.platformId)) {
        const titleElement = this.titleElement?.nativeElement //document.getElementById('title');
        if(titleElement) {
          const computedStyle = window.getComputedStyle(titleElement);
          const lineHeight = parseFloat(computedStyle.lineHeight);
          const containerHeight = titleElement.clientHeight;
          const lineCount = containerHeight / lineHeight;
          // if(lineCount > (window.innerWidth < 767 ? 2 : 1)) {
          //   const displayTitle1 = this.displayTitle.replace(' ...', '')
          //   const currentLength = displayTitle1.split(' ').length;
          //   const newLength = currentLength - 1;
          //   const newString = this.title.split(' ').slice(0, newLength).join(' ')
          //   this.displayTitle = newString+ ' ...';//this.displayTitle.substring(0, this.displayTitle.length - 1);
          //   this.changeDetectionRef.markForCheck()
          //   this.setSpacesTitle();
          // }

          if(lineCount <= (window.innerWidth < 767 ? 2 : 1)) {
            let currentLength = this.displayTitle ? this.displayTitle.split(' ').length : 0;
            if(this.title.split(' ').length > currentLength) {
              this.displayTitle = this.title.split(' ').slice(0, currentLength ? currentLength + 1 : 1).join(' ');
              this.changeDetectionRef.markForCheck()
              this.setSpacesTitle();
            }
          } else {
            this.displayTitle = this.displayTitle.split(' ').slice(0, -1).join(' ')+' ...';
            this.changeDetectionRef.markForCheck();
            if(lineCount > (window.innerWidth < 767 ? 2 : 1)) {
              this.displayTitle = this.displayTitle.replace(' ...', '').trim();
              this.displayTitle = this.displayTitle.split(' ').slice(0, -1).join(' ')+' ...';
              this.changeDetectionRef.markForCheck();
            }
          }
        }
      }
    })
  }
}
